import Spinner from "react-bootstrap/Spinner";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Spinner animation="border" />
        <p>Loading...</p>
      </header>
    </div>
  );
}

export default App;
